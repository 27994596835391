<template>
    <div class="main">
        <section class="start">
            <figure class="start-image">
                <img src="@/assets/img/logo.png" alt="Incroyable Grévin Paris">
            </figure>

            <h2 class="title title-xl">
                A l'occasion de nos 140 ans, <br>
                profitez d'une programmation <br>
                exceptionnelle et inédite!
            </h2>

            <Button v-bind:url="'#'" v-bind:title="'Je découvre'" v-bind:target="'_blank'" />
        </section>

        <section
            v-for="section, index in sections"
            :class="['section', 'section--'+section.layout]"
            :key="index"
            :data-layout="section.layout"
        >
            <div class="section-date" v-if="section.date" v-html="section.date"></div>
            <h2 class="title title-lg color-gold">{{ section.title }}</h2>
            <div class="section-text" v-html="section.text"></div>

            <Button v-bind:url="section.button.url" v-bind:title="section.button.title" v-bind:target="'_blank'" />
        </section>

        <section class="end">
            <div class="end-confetti"></div>

            <figure class="end-image">
                <img src="@/assets/img/logo.png" alt="Incroyable Grévin Paris">
            </figure>

            <h1 class="title title-xl color-gold">Pour ses 140 ans, Grévin comme <br> vous ne l'avez jamais vu</h1>

            <div class="end-text">
                <p>
                    De la magie, du théâtre immersif, des concerts à la bougie ou encore du street art ; <br>
                    année le musée Grévin met les petits plats dans les grands pour souffler ses 140 bougies.
                </p>
                <p>
                    Prenez part à cette fête à prix anniversaire pour les petits <br>
                    et les grands du 1er avril au 1er septembre 2022.
                </p>
            </div>

            <Button v-on:click.native="kickOffTheFestivities" v-bind:url="'#'" v-bind:title="'Lancez les festivités'" />
        </section>

        <div id="canvas-container"></div>
    </div>
</template>

<script>
import Button from './Button.vue'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollTo from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollTo);


export default {
    name: 'Page',
	components: {
		Button,
	},
    props: ['settings', 'sections'],
    data: function() {
        return {
            fworks: null,
            focus: true
        }
    },
    computed: {
        windowIsVisible () {
            return document.visibilityState == 'visible'
        }
    },
    mounted() {
        //
        // set app var
        //
        let app = this;


        //
        // scroll at bottom
        //
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 500);


        //
        // firework class
        //
        let Fireworks = function(){
            /*=============================================================================*/
            /* Utility
            /*=============================================================================*/
            var self = this;
            window.requestAnimFrame=function(){return window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||window.oRequestAnimationFrame||window.msRequestAnimationFrame||function(a){window.setTimeout(a,1E3/60)}}();

            /*=============================================================================*/
            /* Initialize
            /*=============================================================================*/
            self.init = function(){
            self.dt = 0;
                self.oldTime = Date.now();
                self.canvas = document.createElement('canvas');
                self.canvasContainer = document.getElementById('canvas-container');

                // var canvasContainerDisabled = document.getElementById('canvas-container');
                self.canvas.onselectstart = function() {
                    return false;
                };

                self.canvas.width = self.cw = window.innerWidth;
                self.canvas.height = self.ch = window.innerHeight;

                // self.particles = [];
                // self.partCount = 30;
                // self.fireworks = [];
                // self.currentHue = 170;
                // self.partSpeed = 10;
                // self.partSpeedVariance = 5;
                // self.partWind = 50;
                // self.partFriction = 5;
                // self.partGravity = 1;
                // self.hueMin = 0;
                // self.hueMax = 360;
                // self.fworkSpeed = 10;
                // self.fworkAccel = 3;
                // self.hueVariance = 180;
                // self.flickerDensity = 50;
                // self.showShockwave = true;
                // self.showTarget = false;
                // self.clearAlpha = 100;

                for (let [key, value] of Object.entries(app.settings)) {
                    self[key] = value;
                }

                self.mx = self.cw/2;
                self.my = self.ch/2;

                self.canvasContainer.append(self.canvas);
                self.ctx = self.canvas.getContext('2d');
                self.ctx.lineCap = 'round';
                self.ctx.lineJoin = 'round';
                self.lineWidth = 1;
                self.bindEvents();
                self.canvasLoop();

                self.canvas.onselectstart = function() {
                    return false;
                };


            };

            /*=============================================================================*/
            /* Particle Constructor
            /*=============================================================================*/
            var Particle = function(x, y, hue){
                this.x = x;
                this.y = y;
                this.coordLast = [
                    {x: x, y: y},
                    {x: x, y: y},
                    {x: x, y: y}
                ];
                this.angle =app.rand(0, 360);
                this.speed =app.rand(((self.partSpeed - self.partSpeedVariance) <= 0) ? 1 : self.partSpeed - self.partSpeedVariance, (self.partSpeed + self.partSpeedVariance));
                this.friction = 1 - self.partFriction/100;
                this.gravity = self.partGravity/2;
                this.hue =app.rand(hue-self.hueVariance, hue+self.hueVariance);
                this.brightness =app.rand(50, 80);
                this.alpha =app.rand(40,100)/100;
                this.decay =app.rand(10, 50)/1000;
                this.wind = (app.rand(0, self.partWind) - (self.partWind/2))/25;
                this.lineWidth = self.lineWidth;
            };

            Particle.prototype.update = function(index){
                var radians = this.angle * Math.PI / 180;
                var vx = Math.cos(radians) * this.speed;
                var vy = Math.sin(radians) * this.speed + this.gravity;
                this.speed *= this.friction;

                this.coordLast[2].x = this.coordLast[1].x;
                this.coordLast[2].y = this.coordLast[1].y;
                this.coordLast[1].x = this.coordLast[0].x;
                this.coordLast[1].y = this.coordLast[0].y;
                this.coordLast[0].x = this.x;
                this.coordLast[0].y = this.y;

                this.x += vx * self.dt;
                this.y += vy * self.dt;

                this.angle += this.wind;
                this.alpha -= this.decay;

                if(this.alpha < .05){
                    self.particles.splice(index, 1);
                }
            };

            Particle.prototype.draw = function(){
                var coordRand = (app.rand(1,3)-1);
                self.ctx.beginPath();
                self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
                self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
                self.ctx.closePath();
                self.ctx.strokeStyle = 'hsla('+this.hue+', 100%, '+this.brightness+'%, '+this.alpha+')';
                self.ctx.stroke();

                if(self.flickerDensity > 0){
                    var inverseDensity = 50 - self.flickerDensity;
                    if(app.rand(0, inverseDensity) === inverseDensity){
                        self.ctx.beginPath();
                        self.ctx.arc(Math.round(this.x), Math.round(this.y),app.rand(this.lineWidth,this.lineWidth+3)/2, 0, Math.PI*2, false)
                        self.ctx.closePath();
                        var randAlpha = app.rand(50,100)/100;
                        self.ctx.fillStyle = 'hsla('+this.hue+', 100%, '+this.brightness+'%, '+randAlpha+')';
                        self.ctx.fill();
                    }
                }
            };

            /*=============================================================================*/
            /* Create Particles
            /*=============================================================================*/
            self.createParticles = function(x,y, hue){
                var countdown = self.partCount;
                while(countdown--){
                    self.particles.push(new Particle(x, y, hue));
                }
            };

            /*=============================================================================*/
            /* Update Particles
            /*=============================================================================*/
            self.updateParticles = function(){
                var i = self.particles.length;
                while(i--){
                    var p = self.particles[i];
                    p.update(i);
                }
            };

            /*=============================================================================*/
            /* Draw Particles
            /*=============================================================================*/
            self.drawParticles = function(){
                var i = self.particles.length;
                while(i--){
                    var p = self.particles[i];
                    p.draw();
                }
            };

            /*=============================================================================*/
            /* Firework Constructor
            /*=============================================================================*/
            var Firework = function(startX, startY, targetX, targetY){
                this.x = startX;
                this.y = startY;
                this.startX = startX;
                this.startY = startY;
                this.hitX = false;
                this.hitY = false;
                this.coordLast = [
                    {x: startX, y: startY},
                    {x: startX, y: startY},
                    {x: startX, y: startY}
                ];
                this.targetX = targetX;
                this.targetY = targetY;
                this.speed = self.fworkSpeed;
                this.angle = Math.atan2(targetY - startY, targetX - startX);
                this.shockwaveAngle = Math.atan2(targetY - startY, targetX - startX)+(90*(Math.PI/180));
                this.acceleration = self.fworkAccel/100;
                this.hue = self.currentHue;
                this.brightness = app.rand(50, 80);
                this.alpha = app.rand(50,100)/100;
                this.lineWidth = self.lineWidth;
                this.targetRadius = 1;
            };

            Firework.prototype.update = function(index){
                self.ctx.lineWidth = this.lineWidth;

                var vx = Math.cos(this.angle) * this.speed,
                    vy = Math.sin(this.angle) * this.speed;
                this.speed *= 1 + this.acceleration;
                this.coordLast[2].x = this.coordLast[1].x;
                this.coordLast[2].y = this.coordLast[1].y;
                this.coordLast[1].x = this.coordLast[0].x;
                this.coordLast[1].y = this.coordLast[0].y;
                this.coordLast[0].x = this.x;
                this.coordLast[0].y = this.y;

                if(self.showTarget){
                    if(this.targetRadius < 8){
                        this.targetRadius += .25 * self.dt;
                    } else {
                        this.targetRadius = 1 * self.dt;
                    }
                }

                if(this.startX >= this.targetX){
                    if(this.x + vx <= this.targetX){
                        this.x = this.targetX;
                        this.hitX = true;
                    } else {
                        this.x += vx * self.dt;
                    }
                } else {
                    if(this.x + vx >= this.targetX){
                        this.x = this.targetX;
                        this.hitX = true;
                    } else {
                        this.x += vx * self.dt;
                    }
                }

                if(this.startY >= this.targetY){
                    if(this.y + vy <= this.targetY){
                        this.y = this.targetY;
                        this.hitY = true;
                    } else {
                        this.y += vy * self.dt;
                    }
                } else {
                    if(this.y + vy >= this.targetY){
                        this.y = this.targetY;
                        this.hitY = true;
                    } else {
                        this.y += vy * self.dt;
                    }
                }

                if(this.hitX && this.hitY){
                    // var randExplosion = rand(0, 9);
                    let that = this
                    // setTimeout(() => {
                        self.createParticles(that.targetX, that.targetY, that.hue);
                    // }, 150);
                    self.fireworks.splice(index, 1);
                }
            };

            Firework.prototype.draw = function(){
                self.ctx.lineWidth = this.lineWidth;

                var coordRand = (app.rand(1,3)-1);
                self.ctx.beginPath();
                self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
                self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
                self.ctx.closePath();
                self.ctx.strokeStyle = 'hsla('+this.hue+', 100%, '+this.brightness+'%, '+this.alpha+')';
                self.ctx.stroke();

                if(self.showTarget){
                    self.ctx.save();
                    self.ctx.beginPath();
                    self.ctx.arc(Math.round(this.targetX), Math.round(this.targetY), this.targetRadius, 0, Math.PI*2, false)
                    self.ctx.closePath();
                    self.ctx.lineWidth = 1;
                    self.ctx.stroke();
                    self.ctx.restore();
                }

                if(self.showShockwave){
                    self.ctx.save();
                    self.ctx.translate(Math.round(this.x), Math.round(this.y));
                    self.ctx.rotate(this.shockwaveAngle);
                    self.ctx.beginPath();
                    self.ctx.arc(0, 0, 1*(this.speed/5), 0, Math.PI, true);
                    self.ctx.strokeStyle = 'hsla('+this.hue+', 100%, '+this.brightness+'%, '+app.rand(25, 60)/100+')';
                    self.ctx.lineWidth = this.lineWidth;
                    self.ctx.stroke();
                    self.ctx.restore();
                }
            };

            /*=============================================================================*/
            /* Create Fireworks
            /*=============================================================================*/
            self.createFireworks = function(startX, startY, targetX, targetY){

                if (app.windowIsVisible && app.focus) {
                    self.fireworks.push(new Firework(startX, startY, targetX, targetY));
                }
            };

            /*=============================================================================*/
            /* Update Fireworks
            /*=============================================================================*/
            self.updateFireworks = function(){
                var i = self.fireworks.length;
                while(i--){
                    var f = self.fireworks[i];
                    f.update(i);
                }
            };

            /*=============================================================================*/
            /* Draw Fireworks
            /*=============================================================================*/
            self.drawFireworks = function(){
                var i = self.fireworks.length;
                while(i--){
                    var f = self.fireworks[i];
                    f.draw();
                }
            };

            /*=============================================================================*/
            /* Events
            /*=============================================================================*/
            self.bindEvents = function(){
                // $(window).on('resize', function(){
                window.onresize = function() {
                    clearTimeout(self.timeout);
                    self.timeout = setTimeout(function() {
                        self.ctx.lineCap = 'round';
                        self.ctx.lineJoin = 'round';
                    }, 100);
                };

                // $(self.canvas).on('mousedown', function(e){
                let fireEvent = null;
                self.canvas.addEventListener('mousedown', function(e){
                    // var randLaunch = rand(0, 5);
                    self.mx = e.pageX - self.canvasContainer.offsetLeft;
                    self.my = e.pageY - self.canvasContainer.offsetTop;
                    self.currentHue = app.rand(self.hueMin, self.hueMax);
                    self.createFireworks(self.cw/2, self.ch, self.mx, self.my);

                    // $(self.canvas).on('mousemove.fireworks', function(e){
                    fireEvent = self.canvas.addEventListener('mousemove.fireworks', function(e){
                        // var randLaunch = rand(0, 5);
                        self.mx = e.pageX - self.canvasContainer.offsetLeft;
                        self.my = e.pageY - self.canvasContainer.offsetTop;
                        self.currentHue = app.rand(self.hueMin, self.hueMax);
                        self.createFireworks(self.cw/2, self.ch, self.mx, self.my);
                    });

                });

                // $(self.canvas).on('mouseup', function(){
                self.canvas.addEventListener('mouseup', function(){
                    self.canvas.removeEventListener('mousemove.fireworks', fireEvent);
                });

            }

            /*=============================================================================*/
            /* Clear Canvas
            /*=============================================================================*/
            self.clear = function(){
                self.particles = [];
                self.fireworks = [];
                self.ctx.clearRect(0, 0, self.cw, self.ch);
            };

            /*=============================================================================*/
            /* Delta
            /*=============================================================================*/
            self.updateDelta = function(){
                var newTime = Date.now();
                self.dt = (newTime - self.oldTime)/16;
                self.dt = (self.dt > 5) ? 5 : self.dt;
                self.oldTime = newTime;
            }

            /*=============================================================================*/
            /* Main Loop
            /*=============================================================================*/
            self.canvasLoop = function(){
                window.requestAnimFrame(self.canvasLoop, self.canvas);
                self.updateDelta();
                self.ctx.globalCompositeOperation = 'destination-out';
                self.ctx.fillStyle = 'rgba(0,0,0,'+self.clearAlpha/100+')';
                self.ctx.fillRect(0,0,self.cw,self.ch);
                self.ctx.globalCompositeOperation = 'lighter';
                self.updateFireworks();
                self.updateParticles();
                self.drawFireworks();
                self.drawParticles();
            };

            self.init();

        }

        let fworks = new Fireworks();
        this.fworks = fworks;


        //
        // set sections animation
        //
        const sections = document.querySelectorAll('.section');

        let canvas = document.querySelector('#canvas-container canvas'),
            cw = canvas.width,
            ch = canvas.height;

        sections.forEach((section) => {
            let layout = section.dataset.layout

            ScrollTrigger.create({
                trigger: section,
                end: "bottom 50%",
                onEnterBack: function() {
                    let initialLaunchCount = app.rand(1, 3);

                    while(initialLaunchCount--){
                        let targetX = app.rand((cw / 4), cw-(cw / 4))

                        if (layout != 'center') {
                            targetX = (layout != 'right') ? app.rand(50, (cw / 2)+100) : app.rand((cw / 2)-100, cw-50);
                        }

                        // startX, startY, targetX, targetY
                        fworks.createFireworks(cw/2, ch, targetX, app.rand(50, ch/2)+50);
                    }

                    gsap.to(section, {opacity: 1, duration: .5, delay: .65});
                },
            });

        });


        //
        // end parallax
        //
        gsap.fromTo('.end-confetti',
            {
                yPercent: 20
            },
            {
                yPercent: -20,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.end',
                    scrub: true
                },
            }
        )


        //
        // blur/focus for fireworks
        //
        window.addEventListener('blur', function() {
            app.focus = false
        })
        window.addEventListener('focus', function() {
            app.focus = true
        })
    },
    methods: {
        rand: function (rMi, rMa) {
            return ~~((Math.random()*(rMa-rMi+1))+rMi)
        },
        kickOffTheFestivities: function (e) {
            e.preventDefault()

            let app = this

            let fworks = this.fworks

            let canvas = document.querySelector('#canvas-container canvas')
            let cw = canvas.width
            let ch = canvas.height

            let sections = document.querySelectorAll('.section')

            let reverseSections = []

            let scrollTl = gsap.timeline({pause: false, onComplete: function() {
                document.querySelector('body').classList.add('scroll')

                var initialLaunchCount = 3;

                while(initialLaunchCount--){
                    fworks.createFireworks(cw/2, ch, app.rand(50, cw-50), app.rand(50, ch/2)+50);
                }

                setInterval(() => {
                    var initialLaunchCount = 3;

                    while(initialLaunchCount--){
                        fworks.createFireworks(cw/2, ch, app.rand(50, cw-50), app.rand(50, ch/2)+50);
                    }
                }, 5000);
            }})

            sections.forEach((section) => {
                reverseSections.unshift(section)
            })

            reverseSections.forEach((section, key) => {
                if (key > 0) {
                    scrollTl.addLabel('section-'+key)
                    scrollTl.to(window, {scrollTo: section.offsetTop, duration: 1.5, ease: 'power4.inOut'}, 'section-'+key+'+=1')
                } else {
                    scrollTl.to(window, {scrollTo: section.offsetTop, duration: 1.5, ease: 'power4.inOut'})
                }
            })

            scrollTl.addLabel('top')
            scrollTl.to(window, {scrollTo: 0, duration: 1.5, ease: 'power4.inOut'}, 'top+=1')
        }
    }
}
</script>

<style scoped lang="scss">
    .main {
        .fireworks, #canvas-container {
            position: fixed;
            inset: 0;

            width: 100vw;
            height: 100vh;

            pointer-events: none;

            canvas {
                position: sticky;
                inset: 0;

                width: 100%;
                height: 100%;

                z-index: 1;
            }
        }

        .section {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            width: 100vw;
            height: 75vh;

            opacity: 0;

            &--left {
                max-width: 64%;
            }

            &--right {
                max-width: 64%;

                margin: {
                    left: 36%;
                }
            }

            &-date {
                font-size: 16px;
                text-transform: uppercase;

                margin: {
                    bottom: 10px
                }
            }

            &-container {
                position: absolute;
                inset: 0;
            }

            .button {
                margin: {
                    top: 64px;
                }
            }
        }

        .end, .start {
            position: relative;

            width: 100vw;
            height: 100vh;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-confetti {
                position: absolute;
                inset: 0;
                z-index: -1;

                background-image: url('../assets/img/confetti.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                box-shadow: inset 0px 0px 40px 40px var(--burgundy);

                pointer-events: none;
            }

            &-image {
                margin: {
                    bottom: 64px;
                }
            }

            &-pre {
                font-size: 16px;
                text-transform: uppercase;
            }

            &-title {
                margin: {
                    bottom: 40px;
                }
            }

            .button {
                margin: {
                    top: 80px;
                }
            }
        }
    }
</style>
