<template>
    <div class="loader">
        <div class="loader-count">
            <div class="loader-hundred">
                <div class="loader-count-before"></div>
                <div class="loader-count-after">1</div>
            </div>
            <div class="loader-tens">
                <div class="loader-count-before">0</div>
                <div class="loader-count-after">1</div>
            </div>
            <div class="loader-unity">
                <div class="loader-count-before">0</div>
                <div class="loader-count-after">1</div>
            </div>
        </div>
        <div class="loader-four">4</div>
    </div>
</template>

<script>
import gsap from "gsap"

export default {
    name: 'Loader',
    mounted() {
        let loaderTL = gsap.timeline({pause: false})

        let prevTens = 0

        for (let count = 0; count < 100; count++) {
            let digits = (''+count).split('')

            let unit = parseInt(digits.pop())
            loaderTL.to('.loader-unity .loader-count-before, .loader-unity .loader-count-after', {y: '-100%', duration: .03}, 'update-'+count)
            loaderTL.set('.loader-unity .loader-count-before, .loader-unity .loader-count-after', {y: '0'})
            loaderTL.add(function() {
                document.querySelector('.loader-unity .loader-count-before').innerHTML = unit;
                document.querySelector('.loader-unity .loader-count-after').innerHTML = (unit + 1 > 9)? 0 : parseInt(unit) + 1;
            })

            if (digits.length > 0) {
                let tens = parseInt(digits.pop())

                if (tens != prevTens) {
                    loaderTL.to('.loader-tens .loader-count-before, .loader-tens .loader-count-after', {y: '-100%', duration: .03}, 'update-'+count)
                    loaderTL.set('.loader-tens .loader-count-before, .loader-tens .loader-count-after', {y: '0'})
                    loaderTL.add(function() {
                        document.querySelector('.loader-tens .loader-count-before').innerHTML = tens;
                        document.querySelector('.loader-tens .loader-count-after').innerHTML = (tens + 1 > 9)? 0 : parseInt(tens) + 1;
                    })

                    prevTens = tens
                }
            }
        }

        loaderTL.addLabel('hundred')
        loaderTL.add(function() {
            document.querySelector('.loader-unity .loader-count-after').innerHTML = 0;
        })
        loaderTL.to('.loader-unity .loader-count-before, .loader-unity .loader-count-after', {y: '-100%', duration: .5, ease: 'power1.easeout'}, 'hundred')

        loaderTL.add(function() {
            document.querySelector('.loader-tens .loader-count-after').innerHTML = 0;
        })
        loaderTL.to('.loader-tens .loader-count-before, .loader-tens .loader-count-after', {y: '-100%', duration: .5, ease: 'power1.easeout'}, 'hundred')

        loaderTL.to('.loader-hundred .loader-count-before, .loader-hundred .loader-count-after', {y: '-100%', duration: .5, ease: 'power1.easeout'}, 'hundred')

        loaderTL.addLabel('140')
        loaderTL.to('.loader-tens', {opacity: .2, duration: .5, ease: 'power1.easeout'}, '140+=.5')

        loaderTL.to('.loader-four', {opacity: 1, duration: 1.2, ease: 'power1.easeout'})

        loaderTL.addLabel('zoom')
        loaderTL.to('.loader', {scale: 4, opacity: 0, duration: .75})
        loaderTL.to('.loader', {y: '-100%', duration: .75})
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
    position: fixed;
    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--burgundy);

    pointer-events: none;

    &-count {
        position: relative;

        display: flex;
        justify-content: center;

        font-family: 'Work Sans';
        font-size: 250px;
        font-style: italic;
        line-height: 180px;

        width: 100%;

        margin: {
            left: -60px
        }

        overflow: hidden;

        > div {
            position: relative;

            width: 150px;

            &.loader-hundred {
                margin: {
                    right: -30px
                }
            }
        }

        &-before {

        }

        &-after {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
        }
    }

    &-four {
        position: absolute;
        inset: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--gold);
        font-family: 'Dancing Script';
        font-size: 300px;

        margin: {
            left: -30px
        }

        opacity: 0;
    }
}
</style>
