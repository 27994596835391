<template>
    <a
        :href="url != '#'? url : false"
        :target="target ? target : false"
        class="button"
    >
        <div class="button-content">{{ title }}</div>
        <div class="button-confetti"></div>
    </a>
</template>

<script>
// import gsap from "gsap"

export default {
    name: 'Button',
    props: ['url', 'title', 'target'],
    mounted() {
        let buttons = document.querySelectorAll('.button-confetti')

        for (let confettiContainer of buttons) {
            let confetticount = (confettiContainer.offsetWidth / 50) * 1

            for(var i = 0; i <= confetticount; i++) {
                let span = document.createElement('span')
                    span.setAttribute('class', 'button-particle c'+this.rand(1, 2))
                    span.setAttribute('style', 'top:' + this.rand(10,50) + '%; left:' + this.rand(0,100) + '%;width:' + this.rand(6,8) + 'px; height:' + this.rand(3,4) + 'px;animation-delay: ' + (this.rand(0,30)/10) + 's;')

                confettiContainer.append(span);
            }
        }
    },
    methods: {
        rand: function (rMi, rMa) {
            return ~~((Math.random()*(rMa-rMi+1))+rMi)
        },
    }
}
</script>

<style scoped lang="scss">

</style>
