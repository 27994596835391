<template>
	<div id="app">
		<Page v-bind:sections="sections" v-bind:settings="settings"/>
		<Loader />
	</div>
</template>

<script>
// import gsap from "gsap"

import Loader from './components/Loader.vue'
import Page from './components/Page.vue'

export default {
	name: 'App',
	components: {
		Loader,
		Page,
	},
    data: function() {
        return {
            settings: {
                hello: 'hi',
                particles: [],
                partCount: 500,
                fireworks: [],
                currentHue: 170,
                partSpeed: 10,
                partSpeedVariance: 5,
                partWind: 50,
                partFriction: 5,
                partGravity: 1,
                hueMin: 0,
                hueMax: 360,
                fworkSpeed: 10,
                fworkAccel: 3,
                hueVariance: 180,
                flickerDensity: 20,
                showShockwave: false,
                showTarget: false,
                clearAlpha: 100,
            },
            sections: [
                {
                    layout: 'center',
                    date: 'Avril – septembre',
                    title: 'Concerts à la bougie',
                    text: '<p>' +
                        'Pendant l’anniversaire Grévin,<br>'+
                        'profitez de concerts à la bougie exceptionnels<br>'+
                        "dans notre somptueux théâtre à l'italienne! !"+
                    '</p>',
                    button: {
                        title: 'Je réserve ! 🕯',
                        url: '#'
                    }
                },
                // {
                //     layout: 'left',
                //     date: 'de xx à xx',
                //     title: 'Film anniversaire',
                //     text: '<p>' +
                //         'Découvrez une rétrospective inédite<br>' +
                //         'des temps forts du musée dans un film évènement<br>' +
                //         'projeté au théâtre Grévin !' +
                //     '</p>',
                //     button: {
                //         title: 'je veux le voir ! 🎬',
                //         url: '#'
                //     }
                // },
                {
                    layout: 'right',
                    date: 'Du 19 avril au 30 juin',
                    title: 'Apaches de Paris',
                    text: '<p>' +
                        'Rencontrez les Peaky Blinders à la française<br>' +
                        'dans une expérience aux frontière du spectacle,<br>' +
                        "du théâtre immersif et de l’escape game." +
                    '</p>',
                    button: {
                        title: "J'ose l'expérience ! 🔪",
                        url: '#'
                    }
                },
                {
                    layout: 'center',
                    date: '',
                    title: 'Littérature en fête',
                    text: '<p>' +
                        "Durant l’anniversaire de ses 140 ans,<br>" +
                        "Grévin met la littérature à l’honneur et vous offre<br>" +
                        "des livres aux titres bien choisis 😊" +
                    '</p>',
                    button: {
                        title: "Ça m'intéresse !  📚",
                        url: '#'
                    }
                },
                {
                    layout: 'left',
                    date: '',
                    title: 'Des Jeux concours à gogo !',
                    text: '<p>' +
                        'Toute la durée de l’anniversaire des 140 ans de Grévin,<br>'+
                        'le musée vous offre plein de cadeaux !!<br>'+
                        'Gagnez des places pour découvrir nos ateliers de création,<br>'+
                        "participer à la nuit au musée ou assister à une soirée d'entrée<br>"+
                        "d'une personnalité en compagnie de la star<br>"+
                        "et plein d'autres encore... "+
                    '</p>',
                    button: {
                        title: 'Je veux jouer ! 🎲',
                        url: '#'
                    }
                },
                {
                    layout: 'right',
                    date: 'Du 12 avril au 30 juin',
                    title: 'L’armoires aux rêves',
                    text: '<p>' +
                        "Profitez d'une expérience immersive accompagné d'un comédien<br>"+
                        "au cours de laquelle vous participerez au déroulement d’une histoire<br>"+
                        "pleine de rebondissements, dans des décors de cinéma. "+
                    '</p>',
                    button: {
                        title: 'je rentre dans l’armoire ! 🚪',
                        url: '#'
                    }
                },
                {
                    layout: 'left',
                    date: 'Tout le mois de juin',
                    title: 'Juin en fête',
                    text: '<p>' +
                        "Prix anniversaire à -50%<sup>*</sup>, comédiens<br>"+
                        "sur le parcours de visite, redécouvrez l’expérience Grévin<br>"+
                        "durant ce mois anniversaire "+
                    '</p>'+
                    '<p class"ml">*Plus d’informations et conditions à découvrir au clic.</p>'
                    ,
                    button: {
                        title: 'j’en profite ! 🍾',
                        url: '#'
                    }
                },
                {
                    layout: 'right',
                    date: 'Tous les samedis de chaque mois,<br>à partir du 3 septembre',
                    title: 'La Nuit au musée',
                    text: '<p>' +
                        "Chaque premier samedi du mois,<br>"+
                        "découvrez le musée comme vous ne l’avez jamais vu !<br>"+
                        "Expérience de visite inédite et comédiens vous livrent<br>"+
                        "les secrets d’un Grévin méconnu."+
                    '</p>',
                    button: {
                        title: 'je veux découvrir ! ⭐️',
                        url: '#'
                    }
                },
                {
                    layout: 'left',
                    date: 'Du 21 avril au 17 septembre',
                    title: 'L’exposition Zoulliart',
                    text: '<p>' +
                        "Profitez de la visite du musée et découvrez les portraits<br>"+
                        "street art de nos célébrités exposés dans notre somptueuse<br>"+
                        "salle des colonnes, et signés d'un artiste au style<br>"+
                        "pop et urbain de grand talent !"+
                    '</p>',
                    button: {
                        title: 'Je réserve ! 🎨',
                        url: '#'
                    }
                },
            ]
        }
    },
}
</script>

<style lang="scss">
@import './assets/sass/_reset.scss';
@import './assets/sass/_variables.scss';
@import './assets/sass/_base.scss';

@font-face {
    font-family: 'Dancing Script';
    src: url('./assets/fonts/dancing-script/DancingScript-VariableFont_wght.ttf') format('truetype');
	font-weight: 1 999;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/work-sans/WorkSans-Light.woff2') format('woff2'),
        url('./assets/fonts/work-sans/WorkSans-Light.woff') format('woff'),
        url('./assets/fonts/work-sans/WorkSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
</style>
